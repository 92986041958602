import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RouterPath } from '@common';

import Home from '@pages/Home';
import Survey from '@pages/Survey';
import PrmDetail from '@pages/Home/PrmDetail';
import Place from '@pages/Place';
// import Qrcode from '@pages/Qrcode';
import Notice from '@pages/Notice';
import Errors from '@pages/Errors';
import SampleCoupon from '@pages/SampleCoupon';

// 레이아웃
import Layout from './Layout';

function Router({ isAuthorized }) {
  return (
    <Switch>
      <Layout exact path={`${RouterPath.Home}/:profileNm`} component={Home} />

      {/* Navi */}
      <Layout exact path={RouterPath.Place} component={Place} />
      {/* <Layout exact path={RouterPath.Qrcode} component={Qrcode} /> */}
      <Layout exact path={RouterPath.Notice} component={Notice} />
      <Layout exact path={RouterPath.SampleCoupon} component={SampleCoupon} />

      {/* detail */}
      <Layout path={`${RouterPath.PrmDetail}/:idx`} component={PrmDetail} />

      {/* 설문조사 */}
      <Layout exact path={RouterPath.Survey} component={Survey} />

      <Layout component={Errors} />
    </Switch>
  );
}

export default Router;
