import REST from '@common/rest';

const baseURL = process.env.REACT_APP_API_URL;
const API_VERSION = 'api/v1';

export const DOMAIN_COMMON = `${API_VERSION}/common`;
export const DOMAIN_PLACE = `${API_VERSION}/place`;

// PORTAL_003 ::  플레이스 광고 리스트 조회
export const getPlaces = profileNm => {
  return REST.get(`${baseURL + DOMAIN_PLACE}/promotions/${profileNm}`);
};

// PORTAL_004 :: 플레이스 광고 검색
export const findPlace = data => {
  return REST.post(`${baseURL + DOMAIN_PLACE}/find-promo`, data);
};
