/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default React.memo(function Qrcode(props) {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <main id="notice">
      <div className="App">
        <header className="App-header">
          <h1>Welcome!</h1>
        </header>
      </div>
    </main>
  );
});
