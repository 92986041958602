/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Button, Form, FormCheck, FormGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import sub from 'date-fns/sub';
import { getSurvey, submitSurvey } from './crud';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function Survey(props) {
  const history = useHistory();
  const surveyId =
    (history.location.state && history.location.state.surveyId) || null;

  const [surveyData, setSurveyData] = useState({});
  const [answers, setAnswers] = useState([]);
  const getSurveyData = async () => {
    const { data } = await getSurvey(surveyId);
    if (data.code === 200) {
      setSurveyData(data.data);
      let tempList = [];
      data.data.questions.forEach((q, i) => {
        q.option.forEach((o, j) => {
          if (o.selectYn === 'Y') {
            tempList = [
              ...tempList,
              { optionId: o.optionId, questionId: o.questionId },
            ];
          }
        });
      });
      setAnswers(tempList);
    }
  };

  const onSubmit = async event => {
    const target = event.currentTarget;
    try {
      const params = {
        surveyId,
        answers,
      };
      const { data } = await submitSurvey(params);
      if (data.code === 200) {
        Swal.fire({
          text: '설문조사가 완료 되었습니다.',
          confirmButtonText: '확인',
        }).finally(() => {
          history.goBack();
        });
      } else {
        Swal.fire({
          text: RESULT_CODE[`CODE_${data.code}`],
          confirmButtonText: '확인',
        });
      }
    } catch (error) {
      Swal.fire({
        text: RESULT_CODE.CODE_500,
        confirmButtonText: '확인',
      });
    }
    target.disabled = false;
  };

  const singleChoiceOnClickEvent = option => {
    const isCk =
      answers.find(item => item.optionId === option.optionId) || false;

    let tempList = [];
    if (isCk) {
      tempList = answers.filter(item => item.optionId !== option.optionId);
    } else {
      tempList = [
        ...answers,
        {
          optionId: option.optionId,
          questionId: option.questionId,
        },
      ];
    }
    setAnswers(tempList);
  };

  const majorityChoiceOnClickEvent = option => {
    const isCk =
      answers.find(item => item.questionId === option.questionId) || false;

    let tempList = [];
    if (isCk) {
      const isOp = isCk.optionId === option.optionId;
      if (isOp) {
        tempList = answers.filter(
          item => item.questionId !== option.questionId,
        );
      } else {
        tempList = answers
          .filter(item => item.questionId !== option.questionId)
          .concat({
            optionId: option.optionId,
            questionId: option.questionId,
          });
      }
    } else {
      tempList = [
        ...answers,
        {
          optionId: option.optionId,
          questionId: option.questionId,
        },
      ];
    }
    setAnswers(tempList);
  };

  useEffect(() => {
    if (Number(surveyId) && Number.isInteger(Number(surveyId))) {
      getSurveyData();
    } else {
      Swal.fire({
        text: '잘못된 접근 입니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        history.goBack();
      });
    }
  }, [surveyId]);

  return (
    <main id="survey">
      <header>
        <h3>{surveyData.surveyNm}</h3>
      </header>
      {/* 메인 비쥬얼 */}
      {surveyData.questions &&
        surveyData.questions &&
        surveyData.questions.map((v, i) => {
          return (
            <article className="mt-4">
              <section className="border-line">
                <div>
                  <h6>{`${i + 1}. ${v.question}`}</h6>
                </div>
              </section>
              <Form>
                <div key="inline-radio" className="mb-3">
                  {v.option.map((q, index) => {
                    return (
                      <Form.Group className="flex-form">
                        <Form.Check
                          type="radio"
                          readOnly
                          checked={
                            answers.find(
                              item => item.optionId === q.optionId,
                            ) || false
                          }
                          onClick={e => {
                            if (v.duplicateYn === 'Y') {
                              singleChoiceOnClickEvent(q);
                            } else {
                              majorityChoiceOnClickEvent(q);
                            }
                          }}
                        />
                        <Form.Label className="form-label">
                          {q.answer}
                        </Form.Label>
                      </Form.Group>
                    );
                  })}
                </div>
              </Form>
            </article>
          );
        })}
      {/* 닫기 */}
      <section className="py-3">
        <Button
          variant="blue-bt"
          size="lg"
          className="w-100 btn-connect-wifi btn-radius"
          onClick={e => {
            onSubmit(e);
          }}
        >
          설문 완료
        </Button>
      </section>
    </main>
  );
});
