/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { InputGroup, Form, Button, Ratio, Modal } from 'react-bootstrap';
import { images } from '@assets';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// icon
import SearchIcon from '@assets/svg/search_black_24dp.svg';
import CloseIcon from '@assets/svg/close_black_24dp.svg';

import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useMap,
  useNavermaps,
} from 'react-naver-maps';
import ReactDOMServer from 'react-dom/server';
import { findPlace, getPlaces } from './crud';
import RESULT_CODE from '../../common/ResultCode';
import RouterPath from '../../common/RouterPath';
import Utils from '../../common/Utils';

export default React.memo(function Place(props) {
  const navermaps = useNavermaps();
  const naverMap = useRef();
  const profile = useSelector(state => {
    return state.profile;
  });

  const placeLatLng = useSelector(state => {
    return state.place.placeLatLng;
  });

  const [prmDetailShow, setPrmDetailShow] = useState(false);
  const [searchListShow, setSearchListShow] = useState(false);

  const [connectAp, setConnectAp] = useState();
  const [promotionList, setPromotionList] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState();
  const [isLodding, setIsLodding] = useState(false);
  const [selectPrm, setSelectPrm] = useState();
  const [keyword, setKeyword] = useState('');
  const [searchList, setSearchList] = useState([]);

  const doSearch = () => {
    searchPrmList();
  };
  const closeSearch = () => {
    setKeyword('');
    setSearchList([]);
    setSearchListShow(false);
  };
  const openPrmDetailModal = e => {
    setPrmDetailShow(true);
    setSelectPrm(e);
  };

  const searchPrmList = async () => {
    if (!keyword) {
      Swal.fire({
        text: '홍보명을 입력해주세요.',
        confirmButtonText: '확인',
      });
      return;
    }
    const params = {
      keyword,
      profileNm: profile.profileNm,
    };
    const { data } = await findPlace(params);
    if (data.code === 200) {
      console.log('data :: ', data);
      setSearchList(data.data);
      setSearchListShow(true);
    } else {
      Swal.fire({
        text: RESULT_CODE[`CODE_${data.code}`],
        confirmButtonText: '확인',
      }).finally(() => {
        props.history.push(RouterPath.Error);
      });
    }
  };

  const promoCategorys = {
    FESTIVAL: 'event',
    LANDMARK: 'tour',
    AD(str) {
      switch (str) {
        case '음식점':
          return 'food';
        case '카페':
          return 'cafe';
        case '마트':
          return 'mart';
        case '편의점':
          return 'convenience';
        case '숙박':
          return 'stay';
        case '뷰티':
          return 'beauty';
        case '회사':
          return 'company';
        default:
          return 'etc';
      }
    },
  };

  const getPlaceList = async () => {
    const { data } = await getPlaces(profile.profileNm);
    if (data.code === 200) {
      setConnectAp(data.data.profile);
      if (placeLatLng.ap) {
        setDefaultCenter(
          new navermaps.LatLng(
            data.data.profile.latitude,
            data.data.profile.longitude,
          ),
        );
      } else {
        setDefaultCenter(
          new navermaps.LatLng(placeLatLng.latitude, placeLatLng.longitude),
        );
      }
      const newPromotionList = data.data.promotions.map(el => {
        const detailCategory = el.title.split('#')[1];
        const result = {
          ...el,
          promoCategoryNew:
            el.promoCategory !== 'AD'
              ? promoCategorys[el.promoCategory]
              : detailCategory !== undefined
              ? promoCategorys.AD(detailCategory)
              : 'etc',
        };
        return result;
      });
      setPromotionList(newPromotionList);
      setIsLodding(true);
    } else {
      Swal.fire({
        text: RESULT_CODE[`CODE_${data.code}`],
        confirmButtonText: '확인',
      }).finally(() => {
        props.history.push(RouterPath.Error);
      });
    }
  };

  useEffect(() => {
    getPlaceList();
  }, []);

  return (
    <main id="place">
      <header style={{ zIndex: 1 }}>
        <InputGroup>
          <Form.Control
            placeholder="홍보명 검색"
            value={keyword}
            onChange={e => {
              setKeyword(e.target.value);
            }}
          />
          <Button variant="icon" onClick={doSearch}>
            {/* <i className="material-icons-outlined">search</i> */}
            <SearchIcon height="20px" width="20px" />
          </Button>
        </InputGroup>
        <Button
          variant="icon"
          onClick={closeSearch}
          className={classNames('btn-close-search', {
            'd-flex': searchListShow,
          })}
        >
          {/* <i className="material-icons-outlined">close</i> */}
          <CloseIcon height="20px" width="20px" />
        </Button>
      </header>
      {isLodding && (
        <MapDiv className="map-box" fallback={null}>
          <NaverMap ref={naverMap} defaultCenter={defaultCenter}>
            {placeLatLng.ap && (
              <Marker
                position={defaultCenter}
                icon={{
                  content: ReactDOMServer.renderToString(
                    <span className="map-ap-badge">접속 AP</span>,
                  ),
                  origin: new navermaps.Point(0, 0),
                  anchor: new navermaps.Point(11, 35),
                }}
              />
            )}
            {promotionList &&
              promotionList.length > 0 &&
              promotionList.map((v, i) => {
                const content = ReactDOMServer.renderToString(
                  <Button
                    variant="icon"
                    className={`map-marker ${v.promoCategoryNew}`}
                    onClick={openPrmDetailModal}
                  />,
                );
                const mkPosition = new navermaps.LatLng(
                  v.latitude,
                  v.longitude,
                );
                return (
                  <Marker
                    key={`marker-${i}`}
                    position={mkPosition}
                    icon={{
                      content,
                      origin: new navermaps.Point(0, 0),
                      anchor: new navermaps.Point(11, 35),
                    }}
                    onClick={() => {
                      openPrmDetailModal(v);
                    }}
                  />
                );
              })}
          </NaverMap>
        </MapDiv>
      )}

      {/* 검색결과 */}
      <section
        className={classNames('search-result', { show: searchListShow })}
      >
        {searchList.length > 0 &&
          searchList.map((item, idx) => {
            return (
              <div
                className="prm-list-item"
                key={`prm-${idx}`}
                onClick={() => {
                  props.history.push(`${RouterPath.PrmDetail}/${item.promoId}`);
                }}
              >
                <Ratio aspectRatio="35x19">
                  <div
                    className="img-box"
                    style={{
                      backgroundImage: `url(${Utils.fileViewUrl(
                        item.bannerPath,
                      )})`,
                    }}
                  />
                </Ratio>
                <div className="flex-between">
                  <div>
                    <h5>{item.title}</h5>
                    <p>{item.address}</p>
                  </div>
                  <small>{item.tel}</small>
                </div>
              </div>
            );
          })}
      </section>

      {/* 광고 클릭시 */}
      <Modal
        show={!!selectPrm}
        onHide={() => setSelectPrm()}
        id="modal-prm-detail"
        backdropClassName="opacity-0"
        autoFocus={false}
        restoreFocus={false}
      >
        {selectPrm && (
          <Modal.Body
            onClick={() => {
              props.history.push(
                `${RouterPath.PrmDetail}/${selectPrm.promoId}`,
              );
            }}
          >
            <div className="prm-list-item">
              <Ratio aspectRatio="35x19">
                <div
                  className="img-box"
                  style={{
                    backgroundImage: `url(${Utils.fileViewUrl(
                      selectPrm.bannerPath,
                    )})`,
                  }}
                />
              </Ratio>
              <div className="flex-between">
                <div>
                  <h5>{selectPrm.title}</h5>
                  <p>{selectPrm.description}</p>
                </div>
                <small>{selectPrm.tel}</small>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </main>
  );
});

const prmSearchList = [
  {
    title: 'ESG 관련 ESG 관련 ESG 관련',
    addr: '경상북도 영양군 영양읍 바들양지1길 33 경상북도 영양군 영양읍 바들양지1길 33',
    phone: '054-683-7300',
  },
  {
    title: 'ESG 관련 ESG 관련 ESG 관련',
    addr: '경상북도 영양군 영양읍 바들양지1길 33 경상북도 영양군 영양읍 바들양지1길 33',
    phone: '054-683-7300',
  },
  {
    title: 'ESG 관련 ESG 관련 ESG 관련',
    addr: '경상북도 영양군 영양읍 바들양지1길 33 경상북도 영양군 영양읍 바들양지1길 33',
    phone: '054-683-7300',
  },
  {
    title: 'ESG 관련 ESG 관련 ESG 관련',
    addr: '경상북도 영양군 영양읍 바들양지1길 33 경상북도 영양군 영양읍 바들양지1길 33',
    phone: '054-683-7300',
  },
  {
    title: 'ESG 관련 ESG 관련 ESG 관련',
    addr: '경상북도 영양군 영양읍 바들양지1길 33 경상북도 영양군 영양읍 바들양지1길 33',
    phone: '054-683-7300',
  },
  {
    title: 'ESG 관련 ESG 관련 ESG 관련',
    addr: '경상북도 영양군 영양읍 바들양지1길 33 경상북도 영양군 영양읍 바들양지1길 33',
    phone: '054-683-7300',
  },
  {
    title: 'ESG 관련 ESG 관련 ESG 관련',
    addr: '경상북도 영양군 영양읍 바들양지1길 33 경상북도 영양군 영양읍 바들양지1길 33',
    phone: '054-683-7300',
  },
  {
    title: 'ESG 관련 ESG 관련 ESG 관련',
    addr: '경상북도 영양군 영양읍 바들양지1길 33 경상북도 영양군 영양읍 바들양지1길 33',
    phone: '054-683-7300',
  },
];
