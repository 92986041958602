import React, { useLayoutEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Navi } from '@components';
import PropTypes from 'prop-types';

function Layout({ component: Component, render, isAuthorized, ...rest }) {
  Layout.propTypes = {
    component: PropTypes.elementType,
  };
  Layout.defaultProps = {
    component: PropTypes.element,
  };

  const navi = useLocation();
  useLayoutEffect(() => {
    // go scroll top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [navi.pathname]);

  return (
    <Container fluid className="app-container">
      <div className="app-contents">
        <Route {...rest} render={routeProps => <Component {...routeProps} />} />
        <Navi />
      </div>
    </Container>
  );
}

export default React.memo(Layout);
