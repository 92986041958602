/* eslint-disable import/no-named-as-default */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Redux } from '@common';
import { ScrollTop } from '@components';
import App from './App';
import './common/i18n';

import './index.scss';

// eslint-disable-next-line prefer-const
const { store, persistor } = Redux;
// console.log(store, persistor);

window.onload = () => {
  const lang = window.navigator.language;
  // console.log('[window.onload] language :: ', lang);
  render();
};

function render() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<div>로딩...</div>} persistor={persistor}>
          <BrowserRouter>
            <ScrollTop />
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
