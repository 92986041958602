const RESULT_CODE = {
  CODE_SUCCESS: 'success',
  CODE_500: '확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
  CODE_401: '인증되지 않은 키 입니다.',
  CODE_403: '허용되지 않은 요청입니다.',
  CODE_9000: '[9000] 필수값이 없습니다.',
  CODE_9100: '[9100] 등록 중 오류가 발생했습니다.',
  CODE_9101: '[9101] 수정 중 오류가 발생했습니다.',
  CODE_9102: '[9102] 삭제 중 오류가 발생했습니다.',
  CODE_2000: '[2000] 조회 가능한 사업단위가 없습니다.',
  CODE_2001: '[2001] 조회 가능한 광고가 없습니다.',
  CODE_2002: '[2002] 조회 가능한 쿠폰이 없습니다.',
  CODE_2003: '[2003] 조회 가능한 설문조사가 없습니다.',
};
export default RESULT_CODE;
