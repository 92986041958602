import ci from './ci.png';
import logoWhite from './logo.png';
import logoPrimary from './logo_primary.png';
import chatbot from './chatbot.png';

import icCalendar from './ic_calendar.png';
import icClose from './ic_close.png';
import icCheckedGray from './ic_check_gray.png';
import icCheckedWhite from './ic_check_white.png';
import icDropdown from './ic_dropdown.png';
import icDropup from './ic_dropup.png';
import icUnChecked from './checkbox_unchecked.png';
import icChecked from './checkbox_checked.png';

// dummy
import land1 from './dummy/land1.jpg';
import land2 from './dummy/land2.jpg';
import land3 from './dummy/land3.jpg';
import land4 from './dummy/land4.jpg';
import port1 from './dummy/port1.jpg';
import port2 from './dummy/port2.jpg';
import port3 from './dummy/port3.jpg';

export default {
  ci,
  logoWhite,
  logoPrimary,
  chatbot,

  icCalendar,
  icClose,
  icCheckedGray,
  icCheckedWhite,
  icDropdown,
  icDropup,
  icUnChecked,
  icChecked,

  land1,
  land2,
  land3,
  land4,
  port1,
  port2,
  port3,
};
