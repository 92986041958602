const RouterPath = {
  Home: '/access',
  Place: '/place',
  Qrcode: '/qrcode',
  Notice: '/notice',
  PrmDetail: '/prmDetail',
  SampleCoupon: '/coupon',
  Error: '/error',
  Survey: '/survey',
};
export default RouterPath;
