import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Image, Button } from 'react-bootstrap';
import { images } from '@assets';
import { RouterPath } from '@common';
import { useSelector } from 'react-redux';

export default React.memo(function Errors(props) {
  const profile = useSelector(state => {
    return state.profile;
  });
  const [profileNm, setProfileNm] = useState(
    (profile && profile.profileNm) || '',
  );

  return (
    <main id="errors">
      <h2>404</h2>
      <p className="text-gray">페이지를 찾을 수 없습니다.</p>
      <Button
        variant="primary"
        size="lg"
        onClick={() => props.history.push(`${RouterPath.Home}/${profileNm}`)}
        className="mt-3"
      >
        홈으로 돌아가기
      </Button>
    </main>
  );
});
