/* eslint-disable react/button-has-type */
import html2canvas from 'html2canvas';
import React, { useEffect, useRef, useState } from 'react';

export default React.memo(function SampleCoupon(props) {
  const componentRef = useRef();

  const handleDownloadClick = async () => {
    const canvas = await html2canvas(componentRef.current);
    const imgDataUrl = canvas.toDataURL('image/jpeg');
    const link = document.createElement('a');
    link.href = imgDataUrl;
    link.download = 'SampleCoupon.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <main id="coupon">
      <div className="container_cp">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card text-center coupon-card" ref={componentRef}>
              <div className="card-body">
                <h5 className="card-title">쿠폰</h5>
                <p className="card-text">Save 30%</p>
                <h3 className="coupon-code">1242-2141-2141-1244</h3>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleDownloadClick();
                  }}
                >
                  Download Coupon
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
});
